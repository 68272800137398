@import 'https://cdn.icomoon.io/66770/SJSUiSchool/style.css?qu5nrs';
@import 'https://fast.fonts.net/cssapi/f83373f1-66d1-49cd-b4e0-17422c3ca3a7.css';
@import 'https://i.icomoon.io/public/29f3715ec5/SJSUiSchool/style.css';
body {
  margin: 0;
  padding: 0;
}
a {
  outline: 0;
}
p,
ul,
ol,
dl,
pre,
table,
fieldset,
blockquote {
  margin: 10px 0;
}
img {
  display: block;
  max-width: 100%;
  height: auto;
  border: 0;
}
.title,
.subtitle {
  display: block;
}
.node-header {
  margin-top: 0;
  margin-bottom: 0;
}
.node-header a {
  text-decoration: none;
  display: block;
}
.post-type,
.post-date {
  display: none;
}
.block-title span {
  display: block;
}
.block-menu_block ul.menu {
  margin: 0;
  padding: 0;
}
.block-menu_block ul,
.block-menu_block li {
  list-style: none;
}
.block-menu_block a {
  display: block;
  text-decoration: none;
}
.block-menu_block a.depth-1,
.block-menu_block .block-title a {
  text-transform: uppercase;
}
.node-sidebar .node-contact,
.node-sidebar .link-file,
.node-sidebar .link-twitter,
.node-sidebar .link-related {
  display: block;
}
span.menu-description {
  display: none;
}
@media (min-width: 1200px) {
  .term-nav .block-title {
    margin-top: 0;
  }
}
body div .te-hide-readmore .link-read-more,
body div .te-hide-readmore.node .link-read-more {
  display: none;
}
.node.te-show-related-links .node-links .link-related,
.te-show-related-links .node-links .link-related {
  display: inherit;
}
:root {
  font-size: 16px;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
html {
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
  font-size: 100%;
  text-rendering: optimizeLegibility;
  color: #363636;
  background-color: #efefef;
}
body.user-1,
body.admin-menu {
  margin-top: 0 !important;
  padding-bottom: 0;
}
body.node-content {
  font-family: 'Neue Helvetica W01', Arial, sans-serif;
}
body.search-active {
  overflow: hidden;
}
@media (max-width: 1199px) {
  body #admin-menu {
    display: none;
  }
  body.menu-active {
    overflow: hidden;
  }
}
body,
caption,
th,
td,
input,
textarea,
select,
option,
legend,
fieldset,
button {
  font-family: 'Neue Helvetica W01', Arial, sans-serif;
}
main {
  display: block;
}
h1,
h2,
h3,
h4,
h5,
h6,
.overview .node-header,
.node-full .node-header {
  font-family: 'Neue Helvetica W01', Arial, sans-serif;
}
h1,
.overview .node-header,
.node-full .node-header {
  margin-top: 0;
  margin-bottom: 0;
}
h2 {
  margin-top: 0.667em;
  margin-bottom: 0.667em;
}
h3 {
  margin-top: 0.769em;
  margin-bottom: 0.769em;
}
h4,
h5,
h6,
h6 {
  margin-top: 0.909em;
  margin-bottom: 0.909em;
}
p,
ul,
ol,
dl,
pre,
table,
address,
fieldset {
  margin: 1em 0;
}
hr {
  border-width: 1px 0 0;
}
p.body-alert {
  padding: 15px;
  background-color: #efefef;
  font-weight: 600;
  border-left: 10px solid #e5a823;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
blockquote {
  position: relative;
  margin: 30px 0;
  padding: 0 20px;
  background: none;
  font-size: 30px;
  font-weight: 250;
  line-height: 40px;
  font-style: normal;
}
blockquote:before,
blockquote:after {
  position: absolute;
  top: 1px;
  left: -9px;
  z-index: -1;
}
blockquote:before {
  content: "\e903";
  color: #0055a2;
  font-size: 125px;
}
blockquote:after {
  content: "";
  top: 0;
  left: 0;
  height: 127px;
  width: 127px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8), #ffffff);
}
blockquote.left,
blockquote.right {
  margin: 10px 0;
}
blockquote p {
  margin: 20px 0;
}
@media (min-width: 1200px) {
  blockquote {
    padding: 0 60px;
  }
  blockquote.left,
  blockquote.right {
    padding: 0 30px;
  }
}
h1,
.overview .node-header,
.node-full .node-header {
  font-size: 30px;
  line-height: 1.333;
  color: #363636;
}
h2 {
  font-size: 24px;
  line-height: 1.333;
  color: #363636;
}
h3 {
  font-size: 20px;
  line-height: 1.333;
  color: #0055a2;
}
h4 {
  font-size: 16px;
  line-height: 1.25;
  color: #0055a2;
  font-weight: normal;
  text-transform: uppercase;
}
h5,
h6 {
  font-size: 16px;
  line-height: 1.4;
  color: #0055a2;
}
h6 {
  font-weight: normal;
  text-transform: uppercase;
  color: #363636;
}
img {
  max-width: 100%;
  height: auto;
}
ul,
li {
  margin: 0;
  padding: 0;
}
a,
a:link,
a:visited {
  color: #0055a2;
  text-decoration: none;
}
.node-content a:hover,
.node-contact a:hover,
.node-links a:hover,
.node-content a:visited:hover,
.node-contact a:visited:hover,
.node-links a:visited:hover,
.node-content a:active,
.node-contact a:active,
.node-links a:active,
.node-content a.active,
.node-contact a.active,
.node-links a.active {
  text-decoration: underline;
}
.node-content a:hover,
.node-content a:visited:hover,
.node-content a:active,
.node-content a.active,
.node-contact a:hover,
.node-contact a:visited:hover,
.node-contact a:active,
.node-contact a.active,
.node-links a:hover,
.node-links a:visited:hover,
.node-links a:active,
.node-links a.active {
  text-decoration: underline;
}
.node-content .file a:hover,
.node-content .file a:visited:hover,
.node-content .file a:active,
.node-content .file a.active,
.node-contact .file a:hover,
.node-contact .file a:visited:hover,
.node-contact .file a:active,
.node-contact .file a.active,
.node-links .file a:hover,
.node-links .file a:visited:hover,
.node-links .file a:active,
.node-links .file a.active {
  text-decoration: none;
}
.node-content .file a:hover .link-text,
.node-content .file a:visited:hover .link-text,
.node-content .file a:active .link-text,
.node-content .file a.active .link-text,
.node-contact .file a:hover .link-text,
.node-contact .file a:visited:hover .link-text,
.node-contact .file a:active .link-text,
.node-contact .file a.active .link-text,
.node-links .file a:hover .link-text,
.node-links .file a:visited:hover .link-text,
.node-links .file a:active .link-text,
.node-links .file a.active .link-text {
  text-decoration: underline;
}
.dd-templates-font,
.page-right .block-title:before,
span.ext:after,
.video-play:before,
.link-text:before,
.slideshow-icon:after,
.term-nav .block-title a:before,
.term-nav .block-title a.depth-2:before,
.term-nav .block-title a.depth-3:before,
.block-dd_social .social:after,
.slick-arrow:before {
  font-family: 'dd-templates' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
.clearfix:before,
.clearfix:after,
.page-center:before,
.page-center:after,
.node-full:before,
.node-full:after,
.overview:before,
.overview:after {
  content: " ";
  display: table;
}
.clearfix:after,
.page-center:after,
.node-full:after,
.overview:after {
  clear: both;
}
.block {
  position: relative;
}
.channel,
.subterm,
.node {
  float: none;
  width: auto;
}
.channel,
.subterm,
.block,
.node,
.messages {
  font-family: 'Neue Helvetica W01', Arial, sans-serif;
}
.page,
.above-page,
.below-page {
  font-size: 16px;
  line-height: 1.4;
}
.node {
  font-size: 16px;
}
.node-teaser {
  margin-bottom: 35px;
  padding-bottom: 0;
  clear: both;
  overflow: hidden;
}
.node-teaser .link.comment-add {
  display: none;
}
.node-header {
  line-height: 1.2;
}
.node-header a {
  color: #0055a2;
}
.overview .node-header a,
.overview .node-header a:hover,
.node-full .node-header {
  color: #363636;
  font-weight: 400;
}
.subtitle {
  font-size: 0.8em;
  line-height: 1.333;
  color: #363636;
}
.overview .subtitle,
.node-full .subtitle {
  margin-top: 10px;
  color: #0055a2;
}
.block-title {
  color: #0055a2;
}
.posts .node-header a,
.subterm .node-header a {
  color: #363636;
  font-weight: normal;
}
.posts .node-header a .title,
.subterm .node-header a .title {
  position: relative;
  display: inline;
}
.posts .node-header a .title:after,
.subterm .node-header a .title:after {
  content: "\e901";
  padding: 5px;
  font-size: 16px;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}
.posts .node-header a .subtitle,
.subterm .node-header a .subtitle {
  margin-top: 10px;
  font-weight: 600;
}
.posts .node-header a:hover .title:after,
.subterm .node-header a:hover .title:after {
  opacity: 1;
}
.page-right .block-title {
  padding-top: 10px;
  margin-bottom: 5px;
  border-top: 1px solid #ddd;
  color: #363636;
  font-size: 16px;
}
.page-right .block-title:before {
  float: right;
  font-size: 24px;
  margin-top: -2px;
  margin-left: -6px;
}
.node-image {
  margin-top: 0;
}
.field-image {
  margin-bottom: 1em;
}
.field-image a {
  text-decoration: none;
}
.field-image img,
.node-teaser .field-image img {
  margin: 0;
}
.node-image .field-image,
.node-image .gallery {
  margin-top: 1em;
}
.node-image .gallery .field-image {
  margin-top: 0;
}
.caption {
  font-size: 12px;
  line-height: 1.333;
}
.field-image.has-caption a {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.field-image.has-caption .caption {
  display: table-caption;
  caption-side: bottom;
}
.node-meta {
  display: none;
}
.node-full .node-meta {
  display: block;
}
.meta-item,
.meta-item a {
  color: #999;
}
.meta-item a:hover {
  color: #0471d5;
}
.meta .meta-item {
  border-right-color: #ddd;
}
.meta .event-date {
  display: inherit;
}
.node-content ul,
.node-content ol {
  clear: both;
  margin: 1em 0;
  padding-left: 3em;
  overflow: visible;
}
.node-content li {
  margin-bottom: 0;
}
.slick-slider.slick-dotted {
  margin-bottom: 50px;
}
.slick-slider.slick-dotted .slick-dots {
  bottom: -45px;
  width: auto;
  text-align: left;
  z-index: 3;
}
.slick-slider.slick-dotted .slick-dots li button:before {
  background-color: transparent;
  border: 1px solid #0055a2;
  opacity: 1;
  transition: all 0.2s ease-out;
}
.slick-slider.slick-dotted .slick-dots li button:hover:before,
.slick-slider.slick-dotted .slick-dots li button:focus:before {
  background-color: #0055a2;
}
.slick-slider.slick-dotted .slick-dots li.slick-active button:before {
  background-color: #0055a2;
  opacity: 1;
}
span.ext {
  background: none;
  padding-right: 0;
  padding-left: 0.2em;
}
span.ext:after {
  content: "\e614";
  line-height: inherit;
  vertical-align: middle;
  opacity: 0.5;
}
span.mailto {
  padding-right: 15px;
}
.node-content img,
.node-content .field-image img {
  float: none;
  max-width: 100%;
  margin: 0;
}
.video-play {
  opacity: 0.5;
  background: none;
  transition: opacity 0.3s ease-out;
}
.video-play:before {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -24px;
  margin-left: -24px;
  font-size: 48px;
  content: "\e900";
  color: white;
}
.field-video .video-lightframe {
  display: inline-block;
}
a.video-lightframe:hover .video-play {
  background: transparent;
  opacity: 0.9;
}
.node-contact-wrapper {
  width: 100%;
  margin: 1em 0;
}
.node-contact .field-email {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.admin-content .views-field-field-image-fid img {
  max-width: none;
}
form[action*='/tblock/order'] .views-field-field-image-fid a.imagecache {
  display: block;
  overflow: hidden;
  max-width: 75px;
  max-height: 75px;
}
.add-term,
.edit-term {
  z-index: 1;
}
.block-name {
  font-size: 12px;
}
.ui-dialog {
  font-size: 16px;
}
table.sticky-header {
  margin-top: 66px;
}
@media (min-width: 1200px) {
  table.sticky-header {
    margin-top: 90px;
  }
}
.page {
  max-width: 1920px;
  margin: 0 auto;
}
.layout {
  max-width: 1366px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  box-sizing: border-box;
}
@media (min-width: 640px) {
  .layout {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 1200px) {
  .layout {
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media (min-width: 1366px) {
  .layout {
    padding-left: 130px;
    padding-right: 130px;
  }
}
#block-digitaldcore-comments {
  float: inherit;
}
.node-full .node-contact-wrapper,
.node-sidebar .node-contact-wrapper {
  float: inherit;
}
.node-content table,
.node-content thead,
.node-content tbody,
.node-content tr,
.node-content th,
.node-content td {
  margin: 0;
  padding: 0;
  border: 0;
}
.node-content table {
  display: inline-block;
  width: auto !important;
  max-width: 100%;
  overflow: auto;
  border-spacing: 3px;
  border-collapse: separate;
  background-color: white;
  box-sizing: border-box;
  transition: opacity 0.2s ease-in-out;
}
.node-content table.temp-show {
  display: block !important;
  visibility: hidden !important;
}
.node-content:not(.cke_editable) table {
  opacity: 0;
}
.node-content:not(.cke_editable) table.ready {
  opacity: 1;
}
.node-content th,
.node-content td {
  padding: 10px 15px;
  font-family: 'Neue Helvetica W01', Arial, sans-serif;
  font-size: 14px;
  text-align: left;
}
.node-content th.vertical-text,
.node-content td.vertical-text {
  white-space: nowrap;
}
.node-content th.vertical-text div,
.node-content td.vertical-text div {
  transform: rotate(-90deg);
  width: 16px;
}
.node-content th.vertical-text div span,
.node-content td.vertical-text div span {
  display: inline-block;
  padding: 0 4px 0 3px;
}
.node-content th.vertical-text.center div,
.node-content td.vertical-text.center div {
  margin: 0 auto;
}
.node-content th.vertical-text.right div,
.node-content td.vertical-text.right div {
  margin: 0 0 0 auto;
}
.node-content td {
  vertical-align: top;
}
.node-content td.vertical-text {
  vertical-align: bottom;
}
.node-content th {
  font-weight: 500;
  line-height: 1.2;
  vertical-align: bottom;
  background-color: #0055a2;
  color: white;
}
.node-content tbody tr {
  transition: all 0.2s ease-out;
  background-color: #ebebeb;
}
.node-content tbody td {
  position: relative;
}
.node-content tbody td:not(:first-child):before {
  content: '';
  position: absolute;
  top: 5px;
  left: 0;
  bottom: 5px;
  width: 1px;
}
.node-upper-meta {
  margin-bottom: 0;
  padding-top: 35px;
  border-top: 1px solid #ddd;
  font-size: 14px;
  line-height: 1;
}
.node-teaser.has-meta .node-upper-meta .meta-inner {
  display: inline-block;
  margin-bottom: 10px;
}
.meta .meta-item {
  line-height: 1.2;
  border-right-color: #969696;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.meta .meta-item a {
  text-decoration: none;
}
.node-webform fieldset {
  font-family: 'Neue Helvetica W01', Arial, sans-serif;
  min-width: 100%;
}
.node-links ul.links.inline {
  display: block;
}
.node-links ul.links li {
  clear: left;
  margin: 0.5em 0;
}
.node-links ul.links .link-twitter {
  padding-top: 0.5em;
}
.link {
  display: none;
}
.link a {
  text-decoration: none;
  line-height: 1.2;
}
.link a.active {
  color: #e5a823;
}
.link a:hover:hover .link-text,
.link a:hover:focus .link-text {
  text-decoration: underline;
}
span.file a {
  padding: 0 !important;
  background-image: none !important;
}
/* ========================================================================== *\
   ICONS AND INLINE FILE ATTACHMENTS
\* ========================================================================== */
.link-text {
  position: relative;
}
.link-text:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 15px;
  line-height: inherit;
  /* stylelint-disable declaration-block-single-line-max-declarations */
  /* stylelint-enable */
}
.file-text-html .link-text:before,
.file-text-plain .link-text:before {
  content: "\e601";
}
.file-text-css .link-text:before {
  content: "\e602";
}
.file-application-xml .link-text:before {
  content: "\e603";
}
.file-application-zip .link-text:before {
  content: "\e611";
}
.file-application-pdf .link-text:before {
  content: "\e604";
  color: #f91916;
}
.file-application-msword .link-text:before {
  content: "\e605";
  color: #2a5699;
}
.file-application-vnd-ms-excel .link-text:before {
  content: "\e606";
  color: #227547;
}
.file-application-vnd-openxmlformats-officedocument-spreadsheetml-sheet .link-text:before {
  content: "\e606";
  color: #227547;
}
.file-application-vnd-ms-powerpoint .link-text:before {
  content: "\e607";
  color: #d14524;
}
.file-application-vnd-openxmlformats-officedocument-presentationml-presentation .link-text:before {
  content: "\e607";
  color: #d14524;
}
.file-application-vnd-openxmlformats-officedocument-spreadsheetml-sheet .link-text:before {
  content: "\e606";
  color: #227547;
}
.file-application-vnd-openxmlformats-officedocument-wordprocessingml-document .link-text:before {
  content: "\e605";
  color: #2a5699;
}
.file-application-x-msdos-program .link-text:before {
  content: "\e612";
  color: black;
}
.file-image-gif .link-text:before,
.file-image-png .link-text:before,
.file-image-jpeg .link-text:before {
  content: "\e608";
}
.file-audio-mpeg .link-text:before {
  content: "\e610";
}
.file-video-mpg .link-text:before,
.file-video-mpeg .link-text:before,
.file-video-quicktime .link-text:before {
  content: "\e609";
}
.file a {
  text-decoration: none;
  text-transform: none;
}
.file a .link-text {
  padding-left: 20px;
}
.file a:hover .link-text,
.file a:focus .link-text {
  text-decoration: underline;
}
.link-read-more {
  position: relative;
  display: block;
}
.link-read-more a {
  display: block;
  margin-top: 1em;
  color: #0055a2;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 22px;
}
.link-read-more a:hover,
.link-read-more a:focus {
  text-decoration: none;
}
.not-front .link-read-more a:after {
  font-family: 'theme' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e901";
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
  font-weight: bold;
  line-height: 18px;
  margin-left: 6px;
  margin-bottom: 2px;
}
.not-front .link-read-more a > span {
  position: relative;
  display: inline-block;
}
.not-front .link-read-more a > span:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #0055a2;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}
@media (min-width: 1200px) {
  .not-front .link-read-more a:hover > span:before,
  .not-front .link-read-more a:focus > span:before {
    opacity: 1;
  }
}
.all-dates-link {
  position: relative;
  text-decoration: underline;
  color: #0055a2;
}
.all-dates-link.meta-item {
  padding-right: 30px;
}
.all-dates-link:after {
  position: absolute;
  top: 0;
  right: 10px;
  content: '+';
  font-size: 1.5em;
  transition: all 0.3s linear;
}
.show-all-dates .all-dates-link:after {
  transform: rotate(45deg);
}
.all-dates {
  height: 0;
  overflow: hidden;
}
.show-all-dates .all-dates {
  height: auto;
}
.all-dates .meta-item {
  display: block;
  margin: 10px 0;
  padding: 0;
  border: 0;
}
.accordion-wrapper {
  position: relative;
  display: block;
  border-bottom: 2px solid #fab92a;
}
.accordion-wrapper:first-child {
  margin-top: 10px;
}
.accordion-heading,
.accordion-content {
  opacity: 0;
  transition: opacity 0.2s ease;
}
.accordion-wrapper .accordion-heading,
.accordion-wrapper .accordion-content {
  opacity: 1;
}
.accordion-heading.under-tab a {
  color: #0055a2;
}
.accordion-heading.under-tab.active a {
  color: #0055a2;
}
h3.accordion-heading,
h4.accordion-heading {
  position: relative;
  z-index: 2;
  color: #0055a2;
  font-size: 18px;
  font-weight: 500;
  clear: both;
  margin: 0;
  display: block;
  line-height: 1.3;
  text-decoration: none;
  border: none;
  overflow: hidden;
}
h3.accordion-heading:after,
h4.accordion-heading:after {
  font-family: 'theme' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\d7";
  position: absolute;
  top: -5px;
  right: 0;
  display: block;
  width: 50px;
  height: 73px;
  font-size: 24px;
  font-weight: bold;
  line-height: 73px;
  text-align: center;
  overflow: hidden;
  color: #0055a2;
  cursor: pointer;
  transform: rotate(45deg);
  transition: transform 0.2s ease-out;
}
h3.accordion-heading.active:after,
h4.accordion-heading.active:after {
  transform: rotate(0);
}
h3.accordion-heading a,
h4.accordion-heading a {
  display: block;
  cursor: pointer;
  color: #0055a2;
  text-decoration: none;
  padding: 15px 50px 15px 0;
}
h3.accordion-heading .link-text,
h4.accordion-heading .link-text {
  display: block;
  margin-right: 24px;
}
.accordion-content {
  padding-bottom: 15px;
}
.accordion-content p:first-child {
  margin-top: 0;
}
.field-image a.slideshow-btn {
  display: inline-block;
  position: relative;
}
.field-image a.slideshow-btn .caption {
  display: none;
}
.slideshow-icon {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.7;
  transition: opacity 0.2s ease-out;
  color: white;
}
.slideshow-icon:after {
  position: absolute;
  right: 6%;
  bottom: 0;
  content: "\e901";
  font-size: 48px;
}
.slideshow-icon:hover {
  opacity: 0.9;
}
.photo-gallery .node-image,
.photo-gallery .node-upper-image,
.node-content .photo-gallery {
  opacity: 0;
  transition: opacity 0.1s linear;
}
.photo-gallery .node-image.ready,
.photo-gallery .node-upper-image.ready,
.node-content .photo-gallery.ready {
  opacity: 1;
}
.photo-gallery .node-image.slick-dotted,
.photo-gallery .node-upper-image.slick-dotted,
.node-content .photo-gallery.slick-dotted {
  margin-bottom: 5rem;
}
.photo-gallery .node-item .field-image {
  margin: 0;
}
.photo-gallery .field-image {
  position: relative;
  float: none !important;
  max-width: none !important;
  margin: 0 !important;
}
.photo-gallery .field-image .caption {
  display: none;
  background-color: rgba(12, 34, 92, 0.7);
  padding: 20px;
  color: white;
}
@media (min-width: 640px) {
  .photo-gallery .field-image .caption {
    display: block;
  }
}
.photo-gallery .field-image:not(:first-child) {
  position: absolute;
  top: 0;
}
.photo-gallery .slick-slider {
  margin-bottom: 1em;
}
.photo-gallery .node-image {
  margin-top: 1em;
}
.photo-gallery .slick-track {
  display: block;
  background-color: #17191a;
}
.photo-gallery .slick-track:before,
.photo-gallery .slick-track:after {
  display: none;
}
.photo-gallery .slick-slide {
  float: none;
  display: inline-block;
  margin: 0;
  background-color: #17191a;
}
.photo-gallery .slick-slide a {
  display: block;
  width: auto;
  max-width: 100%;
}
.photo-gallery .slick-slide img {
  margin: 0 auto;
}
.photo-gallery .slick-initialized .slick-slide,
.photo-gallery.slick-initialized .slick-slide {
  vertical-align: middle;
}
.photo-gallery .slick-dots {
  left: -3px;
  bottom: -35px !important;
  margin: 0;
  padding: 0;
}
.photo-gallery .slick-dots li {
  margin: 0;
  padding: 0;
}
.photo-gallery .slick-dots li button {
  padding: 5px 3px;
}
@media (min-width: 1200px) {
  .photo-gallery .slick-dots {
    left: 40px;
  }
}
.photo-gallery .slick-arrow {
  opacity: 0;
  transition: opacity 0.2s;
}
.photo-gallery .slick-arrow:before {
  opacity: 0.5;
}
.photo-gallery .slick-arrow:hover:before,
.photo-gallery .slick-arrow:focus:before {
  opacity: 0.75;
}
.photo-gallery .slick-slider:hover .slick-arrow,
.photo-gallery.slick-slider:hover .slick-arrow {
  opacity: 1;
}
.photo-gallery .slickControls {
  position: absolute;
  bottom: -35px;
  left: -3px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.photo-gallery .slickControls .slick-btn {
  position: static;
  padding: 5px 3px;
  opacity: 0.5;
  transition: all 0.2s ease-out;
}
.photo-gallery .slickControls .slick-btn.slick-play:before {
  border-top-width: 7px;
  border-left-width: 14px;
  border-bottom-width: 7px;
  border-left-color: #0055a2;
}
.photo-gallery .slickControls .slick-btn.slick-pause:before,
.photo-gallery .slickControls .slick-btn.slick-pause:after {
  height: 14px;
  width: 5px;
  background-color: #0055a2;
}
.photo-gallery .slickControls .slick-btn.active {
  opacity: 1;
}
@media (min-width: 1200px) {
  .photo-gallery .slickControls .slick-btn:hover {
    transform: scale(1.2);
  }
}
.item-list .pager li {
  display: inline-block;
  padding: 0;
  margin: 0;
}
.pager li.pager-current,
.pager li a {
  margin: 0 0 4px;
  padding: 3px 10px;
  border: 1px solid #0055a2;
  border-radius: 4px;
  text-decoration: none;
  white-space: nowrap;
  font-weight: normal;
  background-color: #0055a2;
  color: white;
}
.pager li a {
  display: block;
  transition: all 0.3s ease-out;
  background-color: white;
  color: #0055a2;
}
.pager li a:hover {
  background-color: #0055a2;
  color: white;
}
div.dtabs {
  float: none;
  position: relative;
  z-index: 2;
  margin-bottom: -1px;
  padding: 25px 0;
  background-color: white;
}
div.tab {
  float: none;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease-out;
  background-color: #ebebeb;
  border: 1px solid #ebebeb;
  color: #363636;
}
div.tab:hover {
  background-color: #fab92a;
}
div.tab.active {
  background-color: white;
  border: 1px solid #0055a2;
  color: #0055a2;
  pointer-events: none;
}
div.tab span {
  padding: 10px 15px;
}
.ttv-std-1 .switch-tabs-related .dtabs {
  display: none !important;
}
.tabs-by-selection {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  background: #fafafa;
}
.tabs-by-selection:before {
  content: 'Filter by:';
  position: absolute;
  left: 0;
  bottom: 100%;
  font-weight: bold;
  font-size: 14px;
  line-height: 2;
}
.tabs-by-selection:after {
  font-family: 'theme' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e904";
  position: absolute;
  top: 50%;
  right: 0.5em;
  margin-top: -0.5em;
}
.tabs-by-selection select {
  position: relative;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  padding: 0.75em 2.25em 0.75em 1em;
  border: none;
  border-radius: 0;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: pointer;
  background: transparent;
  color: #363636;
}
.tabs-by-selection select::-ms-expand {
  display: none;
}
.tabs-by-selection select::-ms-value {
  background: transparent;
  color: #363636;
}
.tabs-by-selection select:-moz-focusring {
  text-shadow: 0 0 0 #363636;
  color: transparent;
}
.tabs-by-selection select option:not(:checked) {
  color: #363636;
}
.lexicon-links {
  font-size: 0;
  background-color: transparent;
  text-align: left;
}
.lexicon-links a {
  display: inline-block;
  margin: 0 5px 5px 0;
  padding: 8px 11px 5px;
  border: 1px solid #0055a2;
  border-radius: 4px;
  color: #0055a2;
  background-color: transparent;
  font-size: 12px;
  text-decoration: none;
  transition: all 0.3s ease-out;
}
.lexicon-links a:hover {
  color: white;
  border-color: #4c5d6f;
  background-color: #4c5d6f;
}
.lexicon-links .lexicon-alphabar-instructions {
  font-size: 14px;
}
.lexicon-list {
  margin-top: 30px;
}
.lexicon-list a[id*=letter_] {
  font-size: 2em;
}
.lexicon-list dl {
  margin-bottom: 15px;
  padding-bottom: 20px;
}
.lexicon-list dt {
  padding: 5px 10px;
  font-weight: bold;
  background: #a8a8a8;
  color: white;
}
.lexicon-list dd {
  margin: 0;
  padding: 1px 10px;
  border: 1px solid #eee;
  border-top: none;
}
#lightbox {
  position: fixed;
  top: 40px !important;
}
#lightbox2-overlay {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
}
#outerImageContainer {
  max-width: 90%;
}
#lightboxFrame {
  max-width: 100%;
}
#lightboxImage {
  height: auto !important;
}
#imageDataContainer {
  max-width: 90%;
}
.col-1 .views-row {
  width: 100%;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6 {
  clear: both;
}
@media (min-width: 640px) {
  .col-1 .view-content,
  .col-2 .view-content,
  .col-3 .view-content,
  .col-4 .view-content,
  .col-5 .view-content,
  .col-6 .view-content {
    font-size: 0;
  }
  .col-1 .views-row,
  .col-2 .views-row,
  .col-3 .views-row,
  .col-4 .views-row,
  .col-5 .views-row,
  .col-6 .views-row {
    display: inline-block;
    vertical-align: top;
  }
  .col-1 .js-tabs-show,
  .col-2 .js-tabs-show,
  .col-3 .js-tabs-show,
  .col-4 .js-tabs-show,
  .col-5 .js-tabs-show,
  .col-6 .js-tabs-show {
    display: inline-block !important;
  }
  .col-1 .node,
  .col-2 .node,
  .col-3 .node,
  .col-4 .node,
  .col-5 .node,
  .col-6 .node {
    font-size: 16px;
  }
  .col-2 .views-row,
  .col-3 .views-row,
  .col-4 .views-row,
  .col-5 .views-row,
  .col-6 .views-row {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .col-3 .views-row {
    width: 33.33333333%;
  }
  .col-4 .views-row {
    width: 25%;
  }
  .col-5 .views-row {
    width: 20%;
  }
  .col-6 .views-row {
    width: 16.66666667%;
  }
}
/* stylelint-disable declaration-colon-space-after */
@media (min-width: 640px) {
  .gap-0 .view-content {
    margin-left: 0;
  }
  .gap-0 .node {
    margin-left: 0;
  }
  .gap-10 .view-content {
    margin-left: -10px;
  }
  .gap-10 .node {
    margin-left: 10px;
  }
  .gap-15 .view-content {
    margin-left: -15px;
  }
  .gap-15 .node {
    margin-left: 15px;
  }
  .gap-20 .view-content {
    margin-left: -20px;
  }
  .gap-20 .node {
    margin-left: 20px;
  }
  .gap-25 .view-content {
    margin-left: -25px;
  }
  .gap-25 .node {
    margin-left: 25px;
  }
  .gap-30 .view-content {
    margin-left: -30px;
  }
  .gap-30 .node {
    margin-left: 30px;
  }
  .gap-35 .view-content {
    margin-left: -35px;
  }
  .gap-35 .node {
    margin-left: 35px;
  }
  .gap-40 .view-content {
    margin-left: -40px;
  }
  .gap-40 .node {
    margin-left: 40px;
  }
}
/* stylelint-enable */
@media (min-width: 640px) {
  .fiw-25 .node-item:not(.node-content) .field-image {
    max-width: 25% !important;
  }
  .fiw-30 .node-item:not(.node-content) .field-image {
    max-width: 30% !important;
  }
  .fiw-35 .node-item:not(.node-content) .field-image {
    max-width: 35% !important;
  }
}
@media (min-width: 1200px) {
  .fiw-40 .node-item:not(.node-content) .field-image {
    max-width: 40% !important;
  }
  .fiw-50 .node-item:not(.node-content) .field-image {
    max-width: 50% !important;
  }
  .fiw-60 .node-item:not(.node-content) .field-image {
    max-width: 60% !important;
  }
  .fiw-75 .node-item:not(.node-content) .field-image {
    max-width: 75% !important;
  }
}
.fiw-100 .node-item:not(.node-content) .field-image {
  max-width: 100% !important;
}
@media (min-width: 1200px) {
  .fif-lt .node-item:not(.node-content) .field-image {
    float: left;
    margin-left: 0;
    margin-right: 20px;
  }
}
@media (min-width: 1200px) {
  .fif-rt .node-item:not(.node-content) .field-image {
    float: right;
    margin-right: 0;
    margin-left: 20px;
  }
}
.fif-no .node-item:not(.node-content) .field-image {
  float: none !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
@media (min-width: 640px) {
  .fif-lt.fiw-25 .node-item:not(.node-content) .field-image,
  .fif-lt.fiw-30 .node-item:not(.node-content) .field-image,
  .fif-lt.fiw-35 .node-item:not(.node-content) .field-image {
    float: left;
    margin-left: 0;
    margin-right: 20px;
  }
}
@media (min-width: 1200px) {
  .fif-lt.fiw-40 .node-item:not(.node-content) .field-image,
  .fif-lt.fiw-50 .node-item:not(.node-content) .field-image,
  .fif-lt.fiw-60 .node-item:not(.node-content) .field-image,
  .fif-lt.fiw-75 .node-item:not(.node-content) .field-image {
    float: left;
    margin-left: 0;
    margin-right: 20px;
  }
}
@media (min-width: 640px) {
  .fif-rt.fiw-25 .node-item:not(.node-content) .field-image,
  .fif-rt.fiw-30 .node-item:not(.node-content) .field-image,
  .fif-rt.fiw-35 .node-item:not(.node-content) .field-image {
    float: right;
    margin-right: 0;
    margin-left: 20px;
  }
}
@media (min-width: 1200px) {
  .fif-rt.fiw-40 .node-item:not(.node-content) .field-image,
  .fif-rt.fiw-50 .node-item:not(.node-content) .field-image,
  .fif-rt.fiw-60 .node-item:not(.node-content) .field-image,
  .fif-rt.fiw-75 .node-item:not(.node-content) .field-image {
    float: right;
    margin-right: 0;
    margin-left: 20px;
  }
}
.page,
.above-page,
.below-page {
  clear: both;
  position: relative;
  box-sizing: border-box;
}
.page {
  min-height: 100%;
  background-color: white;
  padding-top: 66px;
}
@media (min-width: 1200px) {
  .page {
    padding-top: 90px;
  }
}
.page-header,
.page-main,
.page-footer {
  clear: both;
  position: relative;
  box-sizing: border-box;
}
.page-header {
  z-index: 3;
}
.page-main {
  z-index: 2;
}
.page-footer {
  z-index: 1;
}
.page-top,
.page-center,
.page-bottom {
  position: relative;
  box-sizing: border-box;
}
.page-top {
  z-index: 3;
}
.page-center {
  z-index: 2;
}
.not-front .page-center {
  max-width: 1366px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  box-sizing: border-box;
}
@media (min-width: 640px) {
  .not-front .page-center {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 1200px) {
  .not-front .page-center {
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media (min-width: 1366px) {
  .not-front .page-center {
    padding-left: 130px;
    padding-right: 130px;
  }
}
.page-bottom {
  z-index: 1;
}
.not-front .page-bottom .inner {
  max-width: 1366px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  box-sizing: border-box;
}
@media (min-width: 640px) {
  .not-front .page-bottom .inner {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 1200px) {
  .not-front .page-bottom .inner {
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media (min-width: 1366px) {
  .not-front .page-bottom .inner {
    padding-left: 130px;
    padding-right: 130px;
  }
}
.page-left,
.page-right,
.page-content {
  position: relative;
  box-sizing: border-box;
  outline: none;
}
.page-left {
  z-index: 3;
}
.page-content {
  z-index: 2;
}
.front .page-content {
  margin-top: 0;
  margin-bottom: 0;
}
.page-right {
  z-index: 1;
}
.page-left,
.page-right,
.page-content {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (min-width: 1200px) {
  .page-left,
  .page-right,
  .page-content {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}
@media (min-width: 1200px) {
  .page-left {
    float: left;
    width: 25%;
    margin-bottom: 0;
  }
  .page-left .page-sidebar {
    margin-right: 50px;
  }
}
@media (min-width: 1200px) {
  .sidebar-left .page-content {
    float: left;
    width: 75%;
  }
}
@media (min-width: 1200px) {
  .page-right {
    float: left;
    width: 25%;
  }
  .page-right .page-sidebar {
    margin-left: 50px;
  }
}
@media (min-width: 1200px) {
  .sidebar-right .page-content {
    float: left;
    width: 75%;
  }
}
@media (min-width: 1200px) {
  .two-sidebars .page-content {
    float: left;
    width: 50%;
  }
}
.node-full {
  margin-bottom: 35px;
}
.node-full .node-meta {
  display: block;
  margin-top: 5px;
}
.section-find h2.title,
.section-search h2.title {
  display: none;
}
.section-find .faceted-search-numbering,
.section-search .faceted-search-numbering {
  float: none;
  font-family: 'Neue Helvetica W01', Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
}
.section-find .search-results,
.section-search .search-results {
  font-size: 16px;
  font-family: 'Neue Helvetica W01', Arial, sans-serif;
}
.section-find .search-results dd.search-result-node,
.section-search .search-results dd.search-result-node {
  margin-bottom: 35px;
  padding: 0;
  border: 0;
}
.section-find .search-results .title,
.section-search .search-results .title {
  margin-bottom: 10px;
  padding: 30px 0 0;
  border: none;
  border-top: 1px solid #ddd;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 400;
  background: transparent;
  color: #363636;
}
.section-find .search-results .title a,
.section-search .search-results .title a {
  position: relative;
  display: inline;
  padding-right: 23px;
  color: #363636;
}
.section-find .search-results .title a:after,
.section-search .search-results .title a:after {
  content: "\e901";
  position: absolute;
  right: 0;
  bottom: 6px;
  margin-top: -6px;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}
.section-find .search-results .title a:hover:after,
.section-search .search-results .title a:hover:after {
  opacity: 1;
}
.section-find .search-results .field-image,
.section-search .search-results .field-image {
  margin: 0;
  padding-left: 20px;
  max-width: 15% !important;
}
.section-find .search-results .field-image img,
.section-search .search-results .field-image img {
  width: auto;
  max-height: 150px;
}
.section-find .search-results p,
.section-search .search-results p {
  margin-bottom: 10px;
}
.section-find .search-results span.file a,
.section-search .search-results span.file a {
  padding-left: 0;
}
.section-find .search-results .search-info,
.section-search .search-results .search-info {
  font-size: 16px;
}
#block-faceted_search_ui-1_keyword {
  margin-bottom: 35px;
  padding-bottom: 0;
  border-bottom: none;
}
#edit-keys-wrapper label,
body.section-search #search-form .form-text,
body.section-search #search-form #edit-submit {
  font-size: 16px;
  line-height: 1.2;
  font-family: 'Neue Helvetica W01', Arial, sans-serif;
}
.section-search #search-form > div {
  display: flex;
  max-width: 335px;
  margin: 1em 0;
}
.section-search #search-form > div #edit-keys-wrapper {
  width: 100%;
  margin: 0;
}
.section-search #search-form > div #edit-keys-wrapper input.form-text {
  padding: 10px 15px;
  width: 100%;
  border: 1px solid #ddd;
  box-sizing: border-box;
}
.section-search #search-form > div #edit-submit {
  border: none;
  border-radius: 0;
  background-color: #fab92a;
  background-image: none;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  text-shadow: none;
  padding: 10px 15px;
  margin-top: 24px;
}
.subterm {
  margin-bottom: 0;
}
.subterm-header,
.subterm-content {
  display: none;
}
.subterm-overview .node {
  margin-bottom: 35px;
  padding-bottom: 0;
}
.subterm-overview .node-links .link-read-more {
  display: block;
}
.subterm-link {
  display: none;
}
.modern-directory {
  font-family: 'Neue Helvetica W01', Arial, sans-serif;
}
.modern-directory .view-filters {
  border-top: 1px solid #ddd;
}
.modern-directory .views-exposed-widgets {
  margin: 1em 0;
}
.modern-directory .views-exposed-widget {
  float: none;
  display: inline-block;
  vertical-align: bottom;
  padding: 0;
}
.modern-directory .views-exposed-widget label {
  display: block;
  font-size: 21px;
  margin-bottom: 10px;
}
.modern-directory #edit-keys-wrapper {
  width: 200px;
  height: 31px;
  margin: 0;
  padding: 5px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  background: #f2f2f2;
}
.modern-directory #edit-keys-wrapper input[type=text] {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  line-height: 21px;
  background: #f2f2f2;
}
.modern-directory .views-submit-button .form-submit {
  margin-top: 0;
}
.modern-directory .attachment {
  margin: 1em 0;
}
.modern-directory .item-list ul {
  margin: 0;
  padding: 0;
}
.modern-directory .item-list li {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 0;
  list-style: none;
}
.modern-directory .item-list li.pager-current {
  margin-bottom: 4px;
  padding: 3px 10px;
}
.modern-directory .item-list a {
  display: block;
  margin-bottom: 4px;
  padding: 3px 9px;
  line-height: 21px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease-out;
  background-color: #0055a2;
  color: white;
}
.modern-directory .item-list a:hover {
  background-color: #0471d5;
}
.modern-directory .node {
  clear: both;
}
.modern-directory .node-item {
  display: none;
}
.modern-directory .node-image,
.modern-directory .node-header,
.modern-directory .node-contact,
.modern-directory .node-upper-meta {
  display: block;
}
.modern-directory .field-image {
  margin-top: 0;
  margin-bottom: 20px;
}
.modern-directory .has-image .node-header,
.modern-directory .has-image .node-contact {
  float: right;
  width: 70%;
}
.modern-directory .has-image .node-image {
  float: left;
  max-width: 25% !important;
}
#block-digitaldcore-search {
  width: inherit;
  height: inherit;
}
#block-digitaldcore-search .block-inner,
#block-digitaldcore-search .block-content {
  height: inherit;
}
@media (min-width: 640px) and (max-width: 1199px) {
  #block-digitaldcore-search {
    padding-right: 58%;
  }
}
.dd-search {
  float: none;
  position: relative;
  width: 100%;
  font-size: 0;
  border-bottom: 1px solid #fab92a;
}
.dd-search * {
  height: inherit;
  line-height: inherit;
  box-sizing: border-box;
}
.dd-search > form > div {
  display: flex;
}
.dd-search .dd-search-keywords {
  position: relative;
  z-index: 1;
  float: none;
  display: block;
  width: 100%;
  margin-right: 0;
  border: none;
  overflow: visible;
  background: transparent;
}
.dd-search .dd-search-keywords .form-text {
  opacity: 1;
  width: 100%;
  padding: 5px 42px 5px 0;
  color: #363636;
  font-weight: bold;
  font-size: 17px;
  box-sizing: border-box;
  background: none;
}
.dd-search .dd-search-keywords .form-text::placeholder {
  color: #363636;
}
.dd-search .dd-search-submit {
  position: relative;
  display: block;
  z-index: 2;
  width: 32px;
  height: 32px;
  border: none;
}
.dd-search .dd-search-submit:before {
  content: "\3f";
  position: absolute;
  z-index: -1;
  top: -2px;
  left: 2px;
  font-size: 32px;
  line-height: 1;
  color: #0055a2;
}
.dd-search .dd-search-submit-button {
  width: 100%;
  height: 100%;
  padding: 0;
  cursor: pointer;
}
@media (min-width: 640px) {
  .dd-search {
    margin: 0 0 0 auto;
  }
}
@media (min-width: 1200px) {
  .dd-search {
    top: 50%;
    max-width: 400px;
    margin: -14px 0 0;
  }
  .dd-search .dd-search-keywords .form-text {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 48px;
    font-size: 20px;
    color: white;
  }
  .dd-search .dd-search-keywords .form-text::placeholder {
    color: white;
  }
  .dd-search .dd-search-submit:before {
    color: white;
    transition: color 0.2s ease-out;
  }
  .dd-search .dd-search-submit:hover:before {
    color: #ebebeb;
  }
}
.breadcrumb-nav {
  position: relative;
  padding: 0 35px;
  font-size: 12px;
  line-height: 3em;
  background: #eee;
  color: #aaa;
}
.breadcrumb-nav a {
  color: #363636;
  text-decoration: none;
}
.breadcrumb-nav a:hover {
  color: #0055a2;
}
.breadcrumb-nav .active {
  color: #0471d5;
}
.breadcrumb {
  padding-bottom: 0;
}
.pods {
  margin-bottom: 40px;
}
.pods .views-row {
  position: relative;
  margin-bottom: 35px;
}
.pods .node {
  margin-bottom: 0;
}
.pods .node-inner {
  padding: 30px 20px;
  background: linear-gradient(to bottom, #0055a2, #0471d5);
}
.pods .node-inner:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #0055a2;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}
.pods .node-inner:hover:before {
  opacity: 1;
}
.pods .node-item {
  position: relative;
  display: none;
}
.pods .node-upper-image,
.pods .node-header,
.pods .node-links,
.pods .node-webform {
  display: block;
}
.pods .node-upper-image {
  margin: -30px -20px 0 -20px;
}
.pods .field-image {
  padding-bottom: 10px;
}
.pods .node-header {
  line-height: 1.4;
}
.pods .node-header .title,
.pods .node-header .subtitle {
  color: white;
  font-size: 15px;
}
.pods .node-header .subtitle {
  margin-top: 8px;
  font-weight: 400;
}
.pods .link a {
  color: white;
}
.pods .link a:hover {
  color: #363636;
}
.pods .node-links .link-read-more {
  display: none;
}
.pods .node-webform label {
  display: none;
}
.pods .node-webform .type-textfield {
  margin-bottom: 8px;
}
.pods .node-webform input.form-text {
  font-size: 16px;
  padding: 10px 15px;
  width: 100%;
  border: none;
}
.pods .node-webform input.form-text::placeholder {
  font-size: 16px;
}
.pods .node-webform input.form-submit {
  border: none;
  border-radius: 0;
  background-color: #fab92a;
  background-image: none;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  text-shadow: none;
  width: 100%;
  padding: 10px 15px;
}
.pods .node-unpublished {
  border: 5px solid #faa;
  background-color: transparent;
}
.pods-right .node {
  padding-bottom: 0;
}
@media (min-width: 540px) and (max-width: 639px) {
  .pods-right .view-content {
    margin-left: -3%;
    font-size: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
  .pods-right .views-row {
    display: inline-block;
    width: 47%;
    margin-left: 3%;
    vertical-align: top;
    font-size: 16px;
  }
}
@media (min-width: 540px) {
  .pods-below .view-content {
    margin-left: -3%;
    font-size: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
  .pods-below .views-row {
    display: inline-block;
    width: 47%;
    margin-left: 3%;
    vertical-align: top;
    font-size: 16px;
  }
}
@media (min-width: 720px) {
  .pods-below .view-content {
    margin-left: -3.33333333%;
  }
  .pods-below .views-row {
    width: 30%;
    margin-left: 3.33333333%;
  }
}
@media (min-width: 1200px) {
  .pods-below {
    width: calc(75%);
    margin-right: 0;
    margin-left: auto;
  }
}
.overview .node-upper-meta {
  display: none;
}
.overview .has-meta .node-upper-meta {
  display: block;
}
.overview .node-header a {
  cursor: default;
}
.overview .caption {
  display: block;
}
.term-nav .menu ul {
  position: relative;
  z-index: 1;
  margin-top: -20px;
  height: 20px;
  overflow: hidden;
  opacity: 0;
}
.term-nav .menu .expanded > ul {
  height: auto;
  margin-top: 0;
  overflow: visible;
  opacity: 1;
}
.term-nav .menu li {
  position: relative;
}
.term-nav .menu a {
  position: relative;
  z-index: 2;
}
.term-nav .menu .expanded > a:after,
.term-nav .menu .collapsed > a:after {
  content: "\2295";
  position: absolute;
  top: 50%;
  border-radius: 100%;
  text-align: center;
}
.term-nav .menu .expanded > a:after {
  content: "\2296";
}
@media (max-width: 1199px) {
  .term-nav {
    position: relative;
    z-index: 1;
    margin-left: 0;
    text-align: center;
  }
  .term-nav ul {
    margin: 0;
    padding: 0;
  }
  .term-nav li {
    list-style: none;
  }
  .term-nav .block-inner {
    position: relative;
    z-index: 1;
    display: block;
  }
  .term-nav .block-title {
    position: relative;
    z-index: 2;
    margin-bottom: 0;
  }
  .term-nav .block-title a,
  .term-nav .block-title a.depth-2,
  .term-nav .block-title a.depth-3 {
    position: relative;
    display: block;
    padding: 5px 10px 5px 45px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    line-height: 1.4;
    text-align: left;
    letter-spacing: normal;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 3px;
    color: #484848;
  }
  .term-nav .block-title a:before,
  .term-nav .block-title a.depth-2:before,
  .term-nav .block-title a.depth-3:before {
    font-family: 'theme' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\2335";
    position: absolute;
    top: 6px;
    left: 0;
    bottom: 5px;
    width: 32px;
    height: auto;
    font-size: 18px;
    text-align: center;
    line-height: 18px;
    border-right: 1px solid #ccc;
  }
  .term-nav .block-title a:after,
  .term-nav .block-title a.depth-2:after,
  .term-nav .block-title a.depth-3:after {
    content: ' Menu';
  }
  .term-nav .block-title a.hidden,
  .term-nav .block-title a.depth-2.hidden,
  .term-nav .block-title a.depth-3.hidden {
    display: block !important;
  }
  .term-nav .block-title a .hotspot,
  .term-nav .block-title a.depth-2 .hotspot,
  .term-nav .block-title a.depth-3 .hotspot {
    position: absolute;
    display: block;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .term-nav .block-title span {
    display: inline;
  }
  .term-nav .menu .expanded > a,
  .term-nav .menu .collapsed > a {
    padding-right: 45px;
  }
  .term-nav .menu .expanded > a:after,
  .term-nav .menu .collapsed > a:after {
    right: 5px;
    width: 32px;
    height: 32px;
    margin-top: -8px;
    font-size: 16px;
  }
  .term-nav .menu-name-primary-links > .menu {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    opacity: 0;
    overflow: hidden;
    border-radius: 0 0 3px 3px;
    background-color: #eaeaea;
    box-sizing: border-box;
    transition: all 0.2s ease-out;
  }
  .term-nav .menu-name-primary-links > .menu a {
    display: block;
    margin: 0;
    padding: 12px 16px;
    text-align: left;
    font-size: 16px;
    line-height: 1;
    border-bottom: 1px solid #ddd;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: transparent;
    color: #484848;
  }
  .term-nav .menu-name-primary-links > .menu a.active {
    background-color: #0055a2;
    color: white;
  }
  .term-nav .menu-name-primary-links > .menu a:active {
    color: #0471d5;
  }
  .term-nav .menu-name-primary-links > .menu .active-trail > a:not(.active) {
    color: #0055a2;
  }
  .term-nav .menu-name-primary-links > .menu a.menu-link {
    padding-right: 45px;
  }
  .term-nav .menu-name-primary-links > .menu ul a {
    padding-left: 30px;
  }
  .term-nav .menu-name-primary-links > .menu ul ul a {
    padding-left: 40px;
  }
  .term-nav .menu-name-primary-links > .menu ul ul ul a {
    padding-left: 60px;
  }
  .term-nav.expanded .block-title a {
    border-radius: 3px 3px 0 0;
  }
  .term-nav.expanded .menu-name-primary-links > .menu {
    top: 100%;
    height: auto;
    opacity: 1;
    border: 1px solid #ddd;
  }
}
@media (min-width: 1200px) {
  .term-nav {
    opacity: 0;
    margin-left: 0;
    margin-bottom: 80px;
    font-size: 16px;
    transition: opacity 0.2s 0.2s linear;
  }
  .term-nav.ready {
    opacity: 1;
  }
  .term-nav .menu-level-2 > ul > li {
    margin-bottom: 8px;
  }
  .term-nav ul,
  .term-nav li {
    overflow: visible;
  }
  .term-nav ul {
    margin-left: 0;
  }
  .term-nav ul ul a {
    margin-left: 18px;
  }
  .term-nav ul ul ul a {
    margin-left: 36px;
  }
  .term-nav ul ul ul ul a {
    margin-left: 54px;
  }
  .term-nav .block-title {
    margin-bottom: 0;
    padding-top: 0;
    border-top: 0;
  }
  .term-nav .block-title a,
  .term-nav .block-title a.depth-2,
  .term-nav .block-title a.depth-3 {
    padding: 10px 0;
    border-bottom: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: normal;
    color: #363636;
  }
  .term-nav .menu a {
    padding: 5px 0;
    border-bottom: none;
    font-size: 15px;
    font-weight: normal;
    line-height: 1.2;
    color: #363636;
  }
  .term-nav .menu a .menu-title {
    display: block;
  }
  .term-nav .menu a:hover {
    text-decoration: underline;
  }
  .term-nav .menu a.active-trail {
    font-weight: 600;
  }
  .term-nav .menu a.active {
    color: #0055a2;
    font-weight: 600;
  }
  .term-nav .menu ul {
    transition: all 0.2s ease-out;
  }
  .term-nav .menu .expanded > a,
  .term-nav .menu .collapsed > a {
    padding-right: 28px;
  }
  .term-nav .menu .expanded > a:after,
  .term-nav .menu .collapsed > a:after {
    right: 0;
    width: 14px;
    height: 14px;
    margin-top: -9px;
    border: 2px solid;
    color: #0055a2;
    font-size: 14px;
    transition: all 0.2s ease-out;
    border-color: #e5a823;
  }
}
.appearing-nav {
  font-size: 16px;
}
.appearing-nav #nav-back {
  display: none;
}
.appearing-nav .small {
  display: none;
}
.appearing-nav ul.links li {
  margin-left: 0;
  margin-right: 0;
}
.appearing-nav ul.links .first a {
  border-top: none;
}
.appearing-nav ul.links li a {
  margin: 0;
  padding: 5px 0;
  border-bottom: none;
  line-height: 1.2;
}
.appearing-nav ul.links li a:hover {
  text-decoration: underline;
}
.block-dd_social {
  margin-bottom: 35px;
}
.block-dd_social .block-title {
  margin-bottom: 10px;
}
.block-dd_social ul {
  margin: 0;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.block-dd_social .item-list ul li {
  margin: 0;
  list-style: none;
}
.block-dd_social li {
  display: inline-block;
}
.block-dd_social span.ext {
  display: none;
}
.block-dd_social .social {
  display: block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin-right: 5px;
  border-radius: 100%;
  text-align: center;
  text-decoration: none;
  background-color: #ddd;
  transition: background-color 0.3s ease-out;
}
.block-dd_social .social:after {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  color: white;
}
.block-dd_social a.social-facebook:after {
  content: "\e700";
}
.block-dd_social a.social-facebook:hover,
.block-dd_social a.social-facebook:active {
  background-color: #3c5a9b;
}
.block-dd_social a.social-google:after {
  content: "\e703";
}
.block-dd_social a.social-google:hover,
.block-dd_social a.social-google:active {
  background-color: #db4c3f;
}
.block-dd_social a.social-linkedin:after {
  content: "\e702";
}
.block-dd_social a.social-linkedin:hover,
.block-dd_social a.social-linkedin:active {
  background-color: #1879b3;
}
.block-dd_social a.social-twitter:after {
  content: "\e701";
}
.block-dd_social a.social-twitter:hover,
.block-dd_social a.social-twitter:active {
  background-color: #30aade;
}
.node-fields {
  font-size: 16px;
}
.node-fields .node-upper-meta {
  display: none;
}
.node-fields .node-image,
.node-fields .node-upper-meta {
  display: none;
}
.node-fields .link-file,
.node-fields .link-related,
.node-fields .link-twitter {
  padding-bottom: 0;
  border: none;
}
.node-fields .node-contact {
  margin-top: 10px;
  margin-bottom: 0;
  border: none;
}
.node-fields .node-contact-wrapper {
  padding: 0;
  background-color: transparent;
}
.node-fields .node-links {
  clear: both;
  margin-bottom: 0;
}
.node-fields .node-links.placeholder-block {
  display: none;
}
.node-fields .node-links .link-text {
  display: inline-block;
  vertical-align: middle;
  line-height: inherit;
}
.node-fields .node-links .link-related .link-text {
  margin-left: 0;
}
.contact-posts .views-row {
  padding-top: 1em;
}
.contact-posts .views-row:not(:first-child) {
  border-top: 1px solid #ddd;
}
.contact-posts .node-item {
  display: none;
}
.contact-posts .node-header,
.contact-posts .node-contact {
  display: block;
}
.contact-posts .title {
  font-size: 18px;
  line-height: 1.3;
}
.contact-posts .subtitle {
  font-size: 14px;
  line-height: 1.3;
}
.profile-posts {
  margin-bottom: 35px;
}
.profile-posts .node-item {
  display: none;
}
.profile-posts .node-upper-meta,
.profile-posts .node-header,
.profile-posts .node-contact,
.profile-posts .node-links {
  display: block;
}
.profile-posts .views-row:first-child .node-upper-meta {
  border-top: 0;
}
.profile-posts .node-upper-meta {
  padding-top: 0.5em;
  margin-bottom: 0.5em;
}
.profile-posts .node-header {
  font-size: 1.286em;
  line-height: 1.333;
}
.profile-posts .node-header .subtitle {
  font-size: 0.778em;
}
.node-content .image {
  /* stylelint-disable declaration-colon-space-after */
  /* stylelint-enable */
}
.node-content .image.circle img {
  border-radius: 100%;
}
.node-content .image.center {
  margin: 0 auto;
}
.node-content .image.center img {
  margin: 0 auto;
}
@media (min-width: 640px) {
  .node-content .image.right {
    float: right;
    max-width: 35% !important;
    margin-right: 0;
    margin-left: 1em;
    margin-bottom: 1em;
  }
  .node-content .image.left {
    float: left;
    max-width: 35% !important;
    margin-left: 0;
    margin-right: 1em;
    margin-bottom: 1em;
  }
}
@media (min-width: 1200px) {
  .node-content .image.right {
    max-width: 50% !important;
  }
  .node-content .image.left {
    max-width: 50% !important;
  }
}
@media (min-width: 640px) {
  .node-content .image.w25 {
    max-width: 25% !important;
  }
  .node-content .image.w30 {
    max-width: 30% !important;
  }
  .node-content .image.w35,
  .node-content .image.w40,
  .node-content .image.w45,
  .node-content .image.w50 {
    max-width: 35% !important;
  }
}
@media (min-width: 1200px) {
  .node-content .image.w40 {
    max-width: 40% !important;
  }
  .node-content .image.w45 {
    max-width: 45% !important;
  }
  .node-content .image.w50 {
    max-width: 50% !important;
  }
  .node-content .image.w55 {
    max-width: 55% !important;
  }
  .node-content .image.w60 {
    max-width: 60% !important;
  }
  .node-content .image.w65 {
    max-width: 65% !important;
  }
  .node-content .image.w70 {
    max-width: 70% !important;
  }
  .node-content .image.w75 {
    max-width: 75% !important;
  }
  .node-content .image.w80 {
    max-width: 80% !important;
  }
  .node-content .image.w85 {
    max-width: 85% !important;
  }
  .node-content .image.w90 {
    max-width: 90% !important;
  }
  .node-content .image.w95 {
    max-width: 95% !important;
  }
  .node-content .image.w100 {
    max-width: 100% !important;
  }
}
@media (min-width: 640px) {
  .node-content .has-caption.caption-left:before,
  .node-content .has-caption.caption-right:before,
  .node-content .has-caption.caption-left:after,
  .node-content .has-caption.caption-right:after {
    content: " ";
    display: table;
  }
  .node-content .has-caption.caption-left:after,
  .node-content .has-caption.caption-right:after {
    clear: both;
  }
  .node-content .has-caption.caption-left a,
  .node-content .has-caption.caption-right a {
    width: 100%;
    display: block;
    table-layout: inherit;
  }
  .node-content .has-caption.caption-left img,
  .node-content .has-caption.caption-right img {
    max-width: 76% !important;
  }
  .node-content .has-caption.caption-left .caption,
  .node-content .has-caption.caption-right .caption {
    margin-top: 0;
    display: inline-block;
    caption-side: inherit;
    width: 21%;
    padding-top: 10px;
    border-top: 1px solid #ddd;
  }
  .node-content .has-caption.caption-left img {
    float: right;
  }
  .node-content .has-caption.caption-left .caption {
    margin-left: 0;
    margin-right: 3%;
    text-align: right;
  }
  .node-content .has-caption.caption-right img {
    float: left;
  }
  .node-content .has-caption.caption-right .caption {
    margin-right: 0;
    margin-left: 3%;
    text-align: left;
  }
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
.two-columns,
.three-columns,
.four-columns {
  clear: both;
}
@media (min-width: 640px) {
  .two-columns,
  .three-columns,
  .four-columns {
    position: relative;
    margin-left: -10px;
    margin-right: -10px;
    font-size: 0;
  }
  .two-columns > *,
  .three-columns > *,
  .four-columns > * {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    vertical-align: top;
    font-size: 16px;
  }
  .two-columns > * {
    width: 50%;
  }
  .three-columns > * {
    width: 33.33333333%;
  }
  .four-columns > * {
    width: 25%;
  }
  .two-columns.lines,
  .three-columns.lines,
  .four-columns.lines {
    margin-left: -20px;
    margin-right: -20px;
  }
  .two-columns.lines > *,
  .three-columns.lines > *,
  .four-columns.lines > * {
    padding-left: 20px;
    padding-right: 20px;
  }
  .two-columns.lines > *:after,
  .three-columns.lines > *:after,
  .four-columns.lines > *:after {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #ccc;
  }
  .two-columns.lines > *:last-child:after,
  .three-columns.lines > *:last-child:after,
  .four-columns.lines > *:last-child:after {
    display: none;
  }
  /* stylelint-disable declaration-colon-space-after */
  .lines.two-columns > *:nth-child(1):after {
    left: 50%;
  }
  .lines.three-columns > *:nth-child(1):after {
    left: 33.33333333%;
  }
  .lines.three-columns > *:nth-child(2):after {
    right: 33.33333333%;
  }
  .lines.four-columns > *:nth-child(1):after {
    left: 25%;
  }
  .lines.four-columns > *:nth-child(2):after {
    left: 50%;
  }
  .lines.four-columns > *:nth-child(3):after {
    right: 25%;
  }
  /* stylelint-enable */
}
@media (min-width: 640px) {
  blockquote.left,
  blockquote.right {
    width: 30%;
  }
  blockquote.left {
    float: left;
    margin-left: 0;
    margin-right: 20px;
  }
  blockquote.right {
    float: right;
    margin-right: 0;
    margin-left: 20px;
  }
}
.node-content table.no-stripes tbody td {
  padding: 6px 0;
}
.node-content table.no-stripes tbody tr.odd,
.node-content table.no-stripes tbody tr:nth-child(odd),
.node-content table.no-stripes tbody tr.even,
.node-content table.no-stripes tbody tr:nth-child(even) {
  background-color: transparent;
}
.node-content table.key-value-data tbody td:first-child {
  text-align: left;
}
.node-content table.key-value-data tbody td:last-child {
  text-align: right;
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-track {
  display: flex;
  position: relative;
  z-index: 1;
  left: 0;
  top: 0;
  margin: 0 auto;
  line-height: 0;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  min-height: 1px;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-slide:focus {
  outline: none;
}
.slick-slide.slick-current {
  z-index: 100;
}
.slick-dots {
  display: block;
  position: absolute;
  z-index: 2;
  bottom: 10px;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
}
.slick-dots li button {
  display: block;
  padding: 5px 3px;
  border: 0;
  outline: none;
  font-size: 0;
  line-height: 0;
  background: transparent;
  color: transparent;
  cursor: pointer;
}
.slick-dots li button:hover {
  outline: none;
}
.slick-dots li button:hover:before {
  opacity: 1;
  transform: scale(1.25);
}
.slick-dots li button:before {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: white;
  opacity: 0.25;
  transition: transform 0.1s;
}
.slick-dots li.slick-active button:before {
  background-color: white;
  opacity: 0.75;
}
.slick-arrow {
  display: block;
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  width: 10%;
  padding: 0;
  border: none;
  font-size: 0;
  line-height: 0;
  outline: none;
  background: transparent;
  color: transparent;
  cursor: pointer;
}
.slick-arrow:before {
  display: block;
  position: absolute;
  top: 50%;
  width: 64px;
  margin-top: -16px;
  font-size: 32px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  transition: opacity 0.2s;
}
.slick-arrow:hover,
.slick-arrow:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-arrow:hover:before,
.slick-arrow:focus:before {
  opacity: 1;
}
.slick-arrow.slick-disabled:before {
  opacity: 0.25;
}
.slick-arrow.slick-prev {
  left: 0;
}
.slick-arrow.slick-prev:before {
  left: 0;
  content: "\e300";
}
.slick-arrow.slick-next {
  right: 0;
}
.slick-arrow.slick-next:before {
  right: 0;
  content: "\e301";
}
.slick-btn {
  display: block;
  position: absolute;
  z-index: 4;
  right: 0;
  bottom: 0;
  padding: 20px;
  border: none;
  font-size: 0;
  line-height: 0;
  outline: none;
  white-space: nowrap;
  background: transparent;
  color: transparent;
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s ease-out;
}
.slick-btn:hover,
.slick-btn:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-btn.slick-play:before {
  content: '';
  display: block;
  border-top: 10px solid transparent;
  border-left: 16px solid white;
  border-bottom: 10px solid transparent;
}
.slick-btn.slick-pause:before,
.slick-btn.slick-pause:after {
  content: '';
  display: inline-block;
  vertical-align: top;
  width: 6px;
  height: 20px;
  background-color: white;
}
.slick-btn.slick-pause:before {
  margin-right: 2px;
}
.slick-btn.slick-pause:after {
  margin-left: 2px;
}
.slick-btn[aria-hidden='true'],
.slick-btn[aria-hidden='true']:hover,
.slick-btn[aria-hidden='true']:focus {
  opacity: 0;
  visibility: hidden;
}
.slick-btn[aria-hidden='false'] {
  z-index: 5;
  opacity: 0.75;
  visibility: visible;
}
.slick-btn[aria-hidden='false']:hover,
.slick-btn[aria-hidden='false']:focus {
  opacity: 1;
}
.theme-font,
blockquote:before,
.posts .node-header a .title:after,
.subterm .node-header a .title:after,
.section-find .search-results .title a:after,
.section-search .search-results .title a:after,
.dd-search .dd-search-submit:before,
.term-nav .menu .expanded > a:after,
.term-nav .menu .collapsed > a:after,
.quicklinks .block-title .block-title-text:before,
.site-toolbar [role=button]:before,
.social-links .views-row article .node-header a:before {
  font-family: 'theme' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 66px;
  transition: all 0.2s ease-out;
  max-width: 1920px;
  margin: 0 auto;
  background: linear-gradient(to right, #0055a2, #0471d5);
}
.page-header:before,
.page-header:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  z-index: -5;
}
.page-header:before {
  background-color: white;
}
.menu-active .page-header:before {
  opacity: 1;
}
.page-header .inner {
  overflow: hidden;
}
.search-active .page-header .inner,
.menu-active .page-header .inner {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.page-header .layout {
  position: relative;
  z-index: 2;
  padding-top: 66px;
  transition: padding-top 0.2s ease-out;
  font-size: 0;
}
.menu-active .page-header {
  height: 100vh;
}
.menu-active .page-header .inner {
  height: 100%;
}
.menu-active .page-header .layout {
  padding-top: 86px;
}
@media (max-width: 1199px) {
  .page-header.locked,
  .menu-active .page-header,
  .search-active .page-header,
  .menu-active .page-header.unlocked,
  .search-active .page-header.unlocked {
    transform: translateY(0);
  }
  .page-header.unlocked {
    transform: translateY(-100%);
  }
}
@media (min-width: 1200px) {
  .page-header {
    height: 90px;
  }
  .page-header:after {
    top: 11px;
    background-image: url(../img/sjsu-logo-bg-desktop.png);
    background-position: top center;
    background-repeat: no-repeat;
  }
  .menu-active .page-header:after {
    opacity: 1;
  }
  .page-header .layout {
    padding-top: 90px;
    margin: 0 auto;
  }
  .menu-active .page-header .layout,
  .search-active .page-header .layout {
    padding-top: 155px;
  }
}
.site-utilities {
  position: relative;
  z-index: 3;
  transition: all 0.2s ease-out;
  visibility: hidden;
  opacity: 0;
}
.menu-active .site-utilities,
.search-active .site-utilities {
  visibility: visible;
  opacity: 1;
}
@media (min-width: 1200px) {
  .site-utilities {
    position: absolute;
    top: 0;
    right: calc(156px);
    width: 0;
    height: 90px;
    overflow: hidden;
  }
  .search-active .site-utilities {
    width: auto;
  }
}
@media (min-width: 1366px) {
  .site-utilities {
    right: calc(186px);
  }
}
.site-navigation {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-out;
}
.menu-active .site-navigation {
  visibility: visible;
  opacity: 1;
}
@media (max-width: 1199px) {
  .search-active .site-navigation {
    opacity: 1;
    visibility: visible;
  }
}
.overlay {
  display: block;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 100%;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 0;
  transition: all 0.2s ease-out;
}
.search-active .overlay {
  bottom: -200px;
  opacity: 1;
}
@media (max-width: 1199px) {
  .menu-active .overlay {
    bottom: -200px;
    opacity: 1;
  }
}
@media (min-width: 1200px) {
  .search-active .overlay:before {
    font-family: 'theme' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\d7";
    position: fixed;
    right: 20px;
    top: 110px;
    width: 70px;
    height: 70px;
    font-size: 70px;
    color: white;
  }
}
.footprint {
  position: relative;
  display: block;
  height: 400px;
}
@media print {
  .footprint {
    display: none;
  }
}
.page-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0471d5;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.page-footer.ready {
  opacity: 1;
}
.page-footer .inner {
  position: relative;
}
.page-footer .footer-cols {
  background: linear-gradient(to bottom, #0055a2, #0471d5);
  padding: 60px 0;
}
.page-footer .footer-cols .footer-cols-wrapper {
  max-width: 1366px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  box-sizing: border-box;
}
@media (min-width: 640px) {
  .page-footer .footer-cols .footer-cols-wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 1200px) {
  .page-footer .footer-cols .footer-cols-wrapper {
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media (min-width: 1366px) {
  .page-footer .footer-cols .footer-cols-wrapper {
    padding-left: 130px;
    padding-right: 130px;
  }
}
.page-footer .footer-cols .footer-cols-inner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 90px;
}
@media (min-width: 1200px) {
  .page-footer .footer-cols {
    padding: 80px 0;
  }
  .page-footer .footer-cols .footer-cols-inner {
    padding-bottom: 0;
  }
}
.page-footer .footerlinks {
  flex-basis: 100%;
}
@media (min-width: 1200px) {
  .page-footer .footerlinks {
    flex-basis: 75%;
  }
}
.page-footer .footerlinks .view-content {
  display: flex;
  flex-wrap: wrap;
}
.page-footer .footerlinks .view-content .views-row {
  flex-basis: 100%;
  box-sizing: border-box;
}
@media (max-width: 1199px) {
  .page-footer .footerlinks .view-content .views-row {
    padding-bottom: 40px;
  }
}
@media (min-width: 1200px) {
  .page-footer .footerlinks .view-content .views-row {
    flex-basis: 33.33333333%;
  }
  .page-footer .footerlinks .view-content .views-row .node-teaser {
    padding-right: 30px;
  }
}
.page-footer .footerlinks .node-teaser {
  margin: 0;
}
.page-footer .footerlinks .node-teaser .node-item {
  display: none;
}
.page-footer .footerlinks .node-teaser .node-header,
.page-footer .footerlinks .node-teaser .node-links {
  display: block;
}
.page-footer .footerlinks .node-teaser .node-header a {
  color: white;
  font-size: 18px;
}
.page-footer .footerlinks .node-teaser .node-links .link-related {
  margin: 15px 0;
}
.page-footer .footerlinks .node-teaser .node-links .link-related a {
  color: white;
  font-size: 18px;
}
.page-footer .footerlinks .node-teaser .node-links .link-related span.ext {
  color: white;
}
@media (min-width: 1200px) {
  .page-footer .footerlinks .node-teaser .node-header a {
    font-size: 15px;
  }
  .page-footer .footerlinks .node-teaser .node-links .link-related a {
    font-size: 15px;
  }
}
.page-footer .footer-col-4 {
  flex-basis: 100%;
}
.page-footer .footer-col-4 .footer-logo img {
  max-width: 270px;
}
.page-footer .footer-col-4 .footer-logo .chhs-slogan {
  display: block;
  color: white;
  margin-top: 5px;
}
.page-footer .footer-col-4 .footer-logo span.ext {
  display: none;
}
@media (min-width: 1200px) {
  .page-footer .footer-col-4 {
    flex-basis: 25%;
  }
  .page-footer .footer-col-4 .footer-logo .chhs-slogan:hover,
  .page-footer .footer-col-4 .footer-logo .chhs-slogan:focus {
    text-decoration: underline;
  }
}
.page-footer .footer-footer {
  background-color: #0055a2;
  padding: 40px 0;
}
@media (min-width: 640px) {
  .page-footer .footer-footer {
    padding: 20px 0;
  }
}
.page-footer .footer-footer .footer-footer-inner {
  max-width: 1366px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 640px) {
  .page-footer .footer-footer .footer-footer-inner {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 1200px) {
  .page-footer .footer-footer .footer-footer-inner {
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media (min-width: 1366px) {
  .page-footer .footer-footer .footer-footer-inner {
    padding-left: 130px;
    padding-right: 130px;
  }
}
.page-footer .footer-footer .footer-footer-left,
.page-footer .footer-footer .footer-footer-right {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
}
.page-footer .footer-footer .footer-footer-left > *,
.page-footer .footer-footer .footer-footer-right > * {
  display: flex;
  align-items: center;
}
.page-footer .footer-footer .footer-footer-left span,
.page-footer .footer-footer .footer-footer-right span {
  color: white;
  font-size: 15px;
}
@media (min-width: 640px) {
  .page-footer .footer-footer .footer-footer-left,
  .page-footer .footer-footer .footer-footer-right {
    flex-basis: 50%;
  }
}
.page-footer .footer-footer .footer-footer-left img {
  height: 90px;
  width: 90px;
  margin-right: 20px;
}
@media (max-width: 639px) {
  .page-footer .footer-footer .footer-footer-left {
    padding-bottom: 40px;
    border-bottom: 2px solid #0471d5;
    margin-bottom: 40px;
  }
}
@media (min-width: 640px) {
  .page-footer .footer-footer .footer-footer-left span {
    padding-right: 40px;
  }
}
.page-footer .footer-footer .footer-footer-right .ischool-caucus-link img {
  max-height: 40px;
}
@media (min-width: 640px) {
  .page-footer .footer-footer .footer-footer-right {
    justify-content: flex-end;
  }
  .page-footer .footer-footer .footer-footer-right .ischool-caucus-link img {
    padding: 25px 0;
  }
}
@media (min-width: 1200px) {
  .page-footer .footer-footer .footer-footer-right a:hover span,
  .page-footer .footer-footer .footer-footer-right a:focus span {
    text-decoration: underline;
  }
}
@media print {
  .page-footer {
    display: none;
  }
}
.site-identity,
.site-identity-alt {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 20px;
  transition: opacity 0.2s ease-out;
}
@media (min-width: 640px) {
  .site-identity,
  .site-identity-alt {
    left: 40px;
  }
}
@media (min-width: 1200px) {
  .site-identity,
  .site-identity-alt {
    left: 100px;
  }
}
@media (min-width: 1366px) {
  .site-identity,
  .site-identity-alt {
    left: 130px;
  }
}
.site-identity {
  opacity: 1;
}
.menu-active .site-identity {
  opacity: 0;
}
@media print {
  .site-identity {
    opacity: 0 !important;
  }
}
.site-identity-alt {
  opacity: 0;
}
.menu-active .site-identity-alt {
  opacity: 1;
}
@media print {
  .site-identity-alt {
    opacity: 1 !important;
  }
}
.site-logo {
  display: block;
  padding-top: 15px;
  padding-bottom: 15px;
}
.site-logo img,
.site-logo .logo-name {
  display: inline-block;
  vertical-align: middle;
}
.site-logo img {
  height: 36px;
}
.msie .site-logo img {
  width: 223px;
}
@media (min-width: 1200px) {
  .site-logo img {
    height: 60px;
  }
  .msie .site-logo img {
    width: 267px;
  }
}
.site-logo-links {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.site-logo-links a {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.site-logo-links a:first-child {
  right: 55%;
}
.site-logo-links a:last-child {
  left: 45%;
}
.main-navigation {
  position: relative;
  float: left;
  width: 100%;
  font-size: 18px;
  transition: margin 0.2s ease-out;
}
@media (max-width: 639px) {
  #navigation.expand .main-navigation {
    margin-left: 80%;
    margin-right: -80%;
  }
}
@media (min-width: 640px) {
  .main-navigation {
    float: right;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .main-navigation {
    width: 40%;
  }
}
.main-navigation .nice-menu {
  padding-bottom: 20px;
}
.main-navigation li {
  position: relative;
  display: block;
}
.main-navigation a.menu-link {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 2px 0;
  font-size: 18px;
  line-height: 1.3;
  font-weight: 300;
  white-space: normal;
  text-decoration: none;
  transition: padding 0.2s ease-out;
  color: #0055a2;
}
.main-navigation a.menu-link.active {
  color: #363636;
}
.main-navigation .menu-title {
  display: block;
}
.main-navigation a.depth-1 {
  position: relative;
  z-index: 2;
  padding: 7px 0 13px;
  font-weight: bold;
  text-transform: uppercase;
}
@media (max-width: 1199px) {
  .main-navigation a.depth-1 {
    font-size: 17px;
  }
}
@media (min-width: 1200px) {
  .main-navigation a.depth-1 {
    font-size: 23px;
  }
}
.main-navigation a.depth-1.active span {
  color: #363636;
}
.main-navigation .menu-title {
  display: inline-block;
  vertical-align: top;
}
.main-navigation .menuparent a.depth-1:after {
  font-family: 'theme' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\2295";
  position: absolute;
  width: 16px;
  height: 16px;
  font-size: 16px;
  text-align: center;
  transition: all 0.2s ease-out;
  border: 2px solid #fab92a;
  border-radius: 100%;
  color: #0055a2;
}
.main-navigation .menuparent a.depth-1.expand:after {
  transform: rotate(45deg);
}
.main-navigation ul.depth-2 {
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: 21px;
  margin-top: -21px;
  opacity: 0;
  transition: all 0.2s ease-out;
}
.main-navigation ul.depth-2 a.menu-link {
  font-size: 16px;
  font-weight: 600;
}
.main-navigation a.expand + ul.depth-2 {
  z-index: 2;
  height: auto;
  margin-top: 0;
  padding-bottom: 20px;
  opacity: 1;
}
.main-navigation a.expand + ul.depth-2 a.menu-link {
  padding: 7px 0;
}
@media (max-width: 1199px) {
  .main-navigation .menuparent a.depth-1 {
    width: 100%;
  }
  .main-navigation .menuparent a.depth-1:after {
    top: 8px;
    right: 0;
  }
  .main-navigation ul.depth-2 {
    padding-left: 20px;
  }
}
@media (min-width: 1200px) {
  .main-navigation a.menu-link {
    font-size: 23px;
    transition: all 0.2s linear;
  }
  .main-navigation a.menu-link:not(.active):before {
    background-color: #fab92a;
  }
  .main-navigation a.menu-link:hover span {
    text-decoration: underline;
  }
  .main-navigation a.depth-1 {
    padding: 12px 0;
  }
  .main-navigation a.depth-1:hover span {
    text-decoration: underline;
  }
  .main-navigation .menuparent a.depth-1:after {
    top: 17px;
    right: -30px;
  }
  .main-navigation ul.depth-2 {
    height: 32px;
    margin-top: -32px;
  }
  .main-navigation ul.depth-2 a {
    pointer-events: none;
  }
  .main-navigation a.expand + ul.depth-2 {
    padding-top: 15px;
  }
  .main-navigation a.expand + ul.depth-2 a {
    pointer-events: auto;
  }
}
.quicklinks {
  float: left;
  width: 100%;
  padding-top: 25px;
}
@media (min-width: 640px) {
  .quicklinks {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .quicklinks {
    width: 60%;
    padding-top: 0;
  }
}
.quicklinks .block-title {
  margin-top: 0;
  margin-bottom: 10px;
}
.quicklinks .block-title .block-title-text {
  position: relative;
  padding-left: 17px;
  color: #0055a2;
  font-size: 17px;
  font-weight: 300;
  line-height: 30px;
  text-transform: uppercase;
}
.quicklinks .block-title .block-title-text:before {
  position: absolute;
  top: 9px;
  left: 0;
  content: "\e902";
  color: #fab92a;
  font-size: 12px;
  transition: transform 0.2s ease-out;
}
@media (max-width: 639px) {
  .quicklinks .block-title .block-title-text {
    cursor: pointer;
  }
  #navigation.expand .quicklinks .block-title .block-title-text:before {
    transform: rotate(180deg);
    transform-origin: center;
  }
}
.quicklinks .block-content {
  box-sizing: border-box;
}
@media (max-width: 639px) {
  .quicklinks .block-content {
    position: absolute;
    top: 65px;
    left: -80%;
    width: 80%;
    padding-right: 30px;
    transition: left 0.2s ease-out;
  }
  #navigation.expand .quicklinks .block-content {
    left: 0;
  }
}
.quicklinks .node-teaser {
  margin: 0;
}
.quicklinks .node-teaser .node-item {
  display: none;
}
.quicklinks .node-teaser .node-header {
  display: block;
}
.quicklinks .node-teaser .node-header a {
  font-size: 15px;
  font-weight: 600;
  padding: 6px 0;
}
@media (min-width: 1200px) {
  .quicklinks .node-teaser .node-header a:hover,
  .quicklinks .node-teaser .node-header a:focus {
    text-decoration: underline;
  }
}
.headerlinks {
  float: left;
  width: 100%;
  padding-bottom: 20px;
  transition: all 0.2s ease-out;
}
@media (max-width: 639px) {
  #navigation.expand .headerlinks {
    margin-left: 80%;
    margin-right: -80%;
  }
}
@media (min-width: 640px) {
  .headerlinks {
    float: right;
    width: 50%;
    padding-top: 0;
    margin-top: -40px;
  }
}
@media (min-width: 1200px) {
  .headerlinks {
    width: 40%;
    margin-top: 0;
  }
}
.headerlinks .node-teaser {
  margin: 0;
}
.headerlinks .node-item {
  display: none;
}
.headerlinks .node-header {
  display: block;
}
.headerlinks .node-header a {
  font-size: 15px;
  line-height: 30px;
  font-weight: 600;
}
@media (min-width: 1200px) {
  .headerlinks .node-header a:hover,
  .headerlinks .node-header a:focus {
    text-decoration: underline;
  }
}
.headerlinks .sjsu-home {
  font-size: 15px;
  line-height: 30px;
  font-weight: 600;
}
@media (min-width: 1200px) {
  .headerlinks .sjsu-home {
    display: none;
  }
}
.site-toolbar {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 20px;
  right: 20px;
  text-align: right;
  line-height: 66px;
}
.site-toolbar .sjsu-home {
  display: none;
  color: white;
  font-size: 15px;
  font-weight: bold;
  vertical-align: middle;
  margin-right: 10px;
  transition: all 0.2s ease-out;
}
@media (min-width: 1200px) {
  .site-toolbar .sjsu-home {
    display: inline-block;
  }
  .site-toolbar .sjsu-home:hover,
  .site-toolbar .sjsu-home:focus {
    text-decoration: underline;
  }
  .search-active .site-toolbar .sjsu-home {
    opacity: 0;
  }
}
@media (min-width: 1200px) and (min-width: 1200px) {
  .menu-active .site-toolbar .sjsu-home {
    color: #0055a2;
  }
  .menu-active .site-toolbar .sjsu-home:hover,
  .menu-active .site-toolbar .sjsu-home:focus {
    text-decoration: underline;
  }
}
.site-toolbar [role=button] {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 66px;
  margin-left: 15px;
  padding: 0;
  border: none;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  outline: none;
  appearance: none;
  overflow: hidden;
  box-shadow: none;
  border-radius: 0;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-out;
  background: transparent;
  color: white;
}
.site-toolbar [role=button]:before {
  display: inline-block;
  vertical-align: middle;
  font-size: 32px;
  line-height: 66px;
}
.site-toolbar [role=button].menu-icon {
  width: 32px;
  text-indent: -9999px;
}
.site-toolbar [role=button].menu-icon:before {
  display: none;
}
.site-toolbar [role=button].menu-icon span {
  display: block;
  position: absolute;
  top: 50%;
  left: 6px;
  right: 0;
  height: 3px;
  background: white;
  transition: background 0s 0.3s;
}
.site-toolbar [role=button].menu-icon span:before,
.site-toolbar [role=button].menu-icon span:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: white;
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0s;
}
.site-toolbar [role=button].menu-icon span:before {
  transition-property: top, transform;
  top: -8px;
}
.site-toolbar [role=button].menu-icon span:after {
  transition-property: bottom, transform;
  bottom: -8px;
}
.menu-active .site-toolbar [role=button].menu-icon span,
.search-active .site-toolbar [role=button].menu-icon span {
  background: 0 0;
}
.menu-active .site-toolbar [role=button].menu-icon span:before,
.search-active .site-toolbar [role=button].menu-icon span:before,
.menu-active .site-toolbar [role=button].menu-icon span:after,
.search-active .site-toolbar [role=button].menu-icon span:after {
  transition-delay: 0s, 0.3s;
}
.menu-active .site-toolbar [role=button].menu-icon span:before,
.search-active .site-toolbar [role=button].menu-icon span:before {
  top: 0;
  transform: rotate(45deg);
}
.menu-active .site-toolbar [role=button].menu-icon span:after,
.search-active .site-toolbar [role=button].menu-icon span:after {
  bottom: 0;
  transform: rotate(-45deg);
}
.menu-active .site-toolbar [role=button].menu-icon span:before,
.menu-active .site-toolbar [role=button].menu-icon span:after {
  background-color: #0055a2;
}
.site-toolbar [role=button].cal-icon:before,
.site-toolbar [role=button].search-icon:before {
  color: white;
}
.menu-active .site-toolbar [role=button].cal-icon:before,
.menu-active .site-toolbar [role=button].search-icon:before {
  color: #0055a2;
}
.site-toolbar [role=button].cal-icon:before {
  content: "\e900";
}
.search-active .site-toolbar [role=button].cal-icon {
  opacity: 0 !important;
}
.site-toolbar [role=button].search-icon:before {
  content: "\3f";
}
.section-find .site-toolbar button.search-icon {
  display: none;
}
@media print {
  .site-toolbar {
    display: none;
  }
}
@media (max-width: 1199px) {
  .site-toolbar button.search-icon {
    display: none;
  }
}
@media (min-width: 640px) {
  .site-toolbar {
    left: 40px;
    right: 40px;
  }
}
@media (min-width: 1200px) {
  .site-toolbar {
    left: 100px;
    right: 100px;
    line-height: 90px;
  }
  .site-toolbar [role=button] {
    height: 90px;
  }
  .site-toolbar [role=button]:hover {
    opacity: 0.6 !important;
  }
  .site-toolbar [role=button]:before {
    line-height: 90px;
  }
}
@media (min-width: 1366px) {
  .site-toolbar {
    left: 130px;
    right: 130px;
  }
}
.social-links {
  position: absolute !important;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 350px;
}
@media (min-width: 640px) {
  .social-links {
    max-width: 280px;
  }
}
@media (min-width: 1200px) {
  .social-links {
    top: 150px;
    right: 0;
    bottom: auto;
    left: 75%;
    max-width: 25%;
  }
}
.social-links .node-item {
  display: none;
  visibility: hidden;
}
.social-links .node-header {
  display: block;
  visibility: visible;
}
.social-links .view-content {
  display: flex;
  justify-content: space-between;
}
.social-links .views-row article {
  margin-bottom: 0;
  overflow: visible;
}
.social-links .views-row article .node-header {
  margin: 0;
}
.social-links .views-row article .node-header a {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  font-size: 0;
  line-height: 0;
  border-radius: 50%;
  background-color: transparent;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
}
.social-links .views-row article .node-header a:before {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  display: block;
  color: white;
  font-size: 22px;
  line-height: 1;
  text-align: center;
  border-radius: 50%;
  padding: 8px 0;
  border: 1px solid white;
  transition: all 0.2s ease-out;
}
.social-links .views-row article .node-header a[href*="pinterest.com"]:before {
  content: "\f231";
}
.social-links .views-row article .node-header a[href*="facebook.com"]:before {
  content: "\e908";
}
.social-links .views-row article .node-header a[href*="youtube.com"]:before {
  content: "\e909";
}
.social-links .views-row article .node-header a[href*="x.com"]:before {
  content: "\e905";
}
.social-links .views-row article .node-header a[href*="instagram.com"]:before {
  content: "\f16d";
}
@media (min-width: 1200px) {
  .social-links .views-row article .node-header a:hover:before,
  .social-links .views-row article .node-header a:focus:before {
    color: #0055a2;
    background-color: #e5a823;
    border-color: #e5a823;
  }
}
@media (min-width: 1200px) {
  .page-header:after {
    background-image: url(../resources/img/sjsu-logo-bg-desktop.png);
  }
}
.page-content {
  font-size: 16px;
}
